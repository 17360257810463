import { useEffect, useState } from "react";
import Pagination from "../../components/Table/pagination";
import useAuth from "../../hooks/useAuth";

import { useNavigate, useLocation } from "react-router-dom";
import UseAxiosPrivate from "../../hooks/useAxiosPrivate";
import Popup from "reactjs-popup";
import CallFeedback from "../../components/Feedbacks/Feedback";
import axios from "axios";
// import axios from "../../utils/axios";
import { ReactComponent as Outgoing } from "../../assets/icons/outgoingcall.svg";

// PDF DOWNLOAD //
import jsPDF from "jspdf";
import "jspdf-autotable";

// EXCEL DOWNLOAD //
import * as XLSX from "xlsx"; // Import all exports from 'xlsx'

import { ReactComponent as Pdf } from "../../assets/icons/pdf-file-svgrepo-com.svg";
import { ReactComponent as Excel } from "../../assets/icons/excel-svgrepo-com.svg";

function CampaignReport(props) {
  const [camplist, setCampList] = useState([]);
  const axiosPrivate = UseAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const BASE_URL = process.env.REACT_APP_API_URL;

  const { auth } = useAuth();

  const [fback, setfback] = useState(false);

  const [campaignRepotData, setCampaignReportData] = useState([]);

  const [campaign, setCampaign] = useState("");

  const [status, setStatus] = useState("");

  /// Fro Date ///
  const [fromDate, setFromDate] = useState("");
  /// To Date ///
  const [toDate, setToDate] = useState("");

  // -------------------------------- PAGINATION -------------------------------- //
  const Initial_pagination = {
    next: "",
    previous: "",
    count: "",
    total_pages: "",
    current_page: 1,
    page_size: 5,
  };

  const [pagination, setPagination] = useState(Initial_pagination);

  const PaginationControl = (type, number) => {
    if (type == "page") {
      if (number <= pagination.total_pages) {
        fetchCampaignReport(number);
      } else {
        fetchCampaignReport(1);
      }
    } else if (type == "last") {
      fetchCampaignReport(pagination.total_pages);
    } else if (type == "prev") {
      if (pagination.current_page > 1) {
        fetchCampaignReport(pagination.current_page - 1);
      }
    } else if (type == "next") {
      if (pagination.current_page < pagination.total_pages) {
        fetchCampaignReport(pagination.current_page + 1);
      }
    } else {
      fetchCampaignReport(1);
    }
  };

  // -------------------------------- END -------------------------------- //

  const initial_fb_data = {
    camp_data: {},
    customer_data: {},
    uid: "",
  };

  const [FBcampData, setFBcampData] = useState(initial_fb_data);

  useEffect(() => {
    if (fback === false) {
      setFBcampData(initial_fb_data);
      fetchCampaignReport(pagination.current_page);
    }
  }, [fback]);

  const initial_fbData = {
    id: "0",
    uid: props.call_id,
    agent_id: "",
    customer_id: "",
    answers: {},
    fbanswer: {},
    comment: "",
    folloup: new Date(),
  };

  const [fbdata, setfbdata] = useState(initial_fbData);

  //  SEARCH //
  const [searchText, setSearchText] = useState("");
  const [userType, setUserType] = useState("");


  const fetchCampaignReport = async (pagenumber) => {
    let post_data;

    if (auth?.user_details?.type === "agent") {
      post_data = {
        user_id: auth?.user_details?.useragent,
        user_type: auth?.user_details?.type,
        download: "yes",
        campaign: campaign || "",
        input_text: searchText,
        status: status,
        user_status: userType,
        page: pagenumber,
        from: fromDate || "",
        to: toDate || "",
        user_status: userType || "",
      };
    } else {
      post_data = {
        user_id: auth?.user_details?.user,
        user_type: auth?.user_details?.type,
        download: "yes",
        campaign: campaign || "",
        input_text: searchText,
        status: status,
        user_status: userType,
        page: pagenumber,
        from: fromDate || "",
        to: toDate || "",
        user_status: userType || "",
      };
    }
    let isMounted = true;
    const controller = new AbortController();

    console.log("--------------REfredgdsjfds-----------------");

    try {
      const response = await axiosPrivate.get(
        process.env.REACT_APP_API_URL + "/contactcentre/campaign_report/",
        {
          signal: controller.signal,
          params: post_data,
        }
      );

      setCampaignReportData(response.data.results);

      setPagination((prevState) => {
        return {
          ...prevState,
          total_pages: response.data?.total_pages || 0,
          previous: response.data?.previous || null,
          next: response.data?.next || null,
          current_page: response.data?.current_page || 0,
          count: response.data?.count || 0,
          page_size: response.data?.page_size || 5,
        };
      });
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const fetchcampaignList = async () => {
    let post_data;

    if (auth?.user_details?.type === "agent") {
      post_data = {
        user_id: auth?.user_details?.useragent,
        user_type: auth?.user_details?.type,
        download: "yes",
        campaign: campaign || "",
        input_text: searchText,
      };
    } else {
      post_data = {
        user_id: auth?.user_details?.user,
        user_type: auth?.user_details?.type,
        download: "yes",
        campaign: campaign || "",
        input_text: searchText,
      };
    }
    let isMounted = true;
    const controller = new AbortController();

    try {
      const response = await axiosPrivate.get(
        process.env.REACT_APP_API_URL + "/contactcentre/allcampaigns/",
        {
          signal: controller.signal,
          params: post_data,
        }
      );

      isMounted && setCampList(response.data);
      // fetchCampaignReport();
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    fetchcampaignList();
  }, []);

  const FeatchFeebackDetails = async (agent_id, uid, customer_id) => {
    let isMounted = true;
    const controller = new AbortController();

    let post_fb_data = {
      calluid: uid,
      agent_id: agent_id,
    };

    try {
      const response = await axiosPrivate.get(
        process.env.REACT_APP_API_URL + "/contactcentre/get_fb_data/",
        {
          signal: controller.signal,
          params: post_fb_data,
        }
      );

      if (isMounted) {
        setFBcampData((prev) => {
          return {
            ...prev,
            camp_data: response.data.camp_data || {},
            customer_data: response.data.customer_data || {},
            uid: uid,
          };
        });

        let fb_data = response.data.fobj_data;

        if (fb_data.follow_up_date === "") {
          setfbdata({
            id: "0",
            uid: uid,
            agent_id: fb_data.feedback_agent,
            customer_id: "",
            answers: {},
            fbanswer: {},
            comment: "",
            folloup: new Date(),
          });
        } else {
          setfbdata((prev) => {
            return {
              ...prev,
              id: fb_data.id,
              agent_id: fb_data.feedback_agent,
              customer_id: fb_data.campaign_customer,
              answers: fb_data.feedback_questions,
              fbanswer: fb_data.feedback_labels,
              comment: fb_data.comment,
              folloup: fb_data.follow_up_date,
              uid: fb_data.uid,
            };
          });
        }
      }
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
    setfback(true);
  };


  const AddFeedback = (agent_id, camp_uid, customer_id, type = "GET") => {
    FeatchFeebackDetails(agent_id, camp_uid, type, customer_id);
  };

  const SearchCampaign = () => {
    fetchCampaignReport(1);
  };

  const SetFeedbackData = (agent_id, fb_id, type = "GET") => {
    FeatchFeebackDetails(agent_id, fb_id, type);
    setfback(true);
  };

  // Download Voice File //
  const DownloadRecord = async (vffile, date, id) => {
    let obj = {
      url: vffile,
      date: date,
    };

    let config = {
      headers: { "Content-Type": "application/json" },
    };

    let CALL_RECORD_URL = "/callrecordfile/";

    try {
      const response = await axios.post(
        BASE_URL + CALL_RECORD_URL,
        obj,
        config
      );

      if (response?.data?.status == 1) {
        // Download succeeded, now fetch campaign report data
        fetchCampaignReport(pagination.current_page);
        const report_data = campaignRepotData.map((item) => {
          if (item.voice_file === vffile) {
            const audioElement = document.getElementById(item.voice_file);
            audioElement.load();
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // -------   Download Data  -------- //

  const [downloadData, setDownloadData] = useState([]);

  const downloadSheet = async (type) => {
    try {
      let post_data;

      if (auth?.user_details?.type === "agent") {
        post_data = {
          user_id: auth?.user_details?.useragent,
          user_type: auth?.user_details?.type,
          campaign: campaign || "",
          from: fromDate || "",
          to: toDate || "",
          input_text: searchText || "",
          status: status || "",  
          user_status: userType || "",
        };
      } else {
        console.log("status  -------------------->  : ",status)
        post_data = {
          user_id: auth?.user_details?.user,
          user_type: auth?.user_details?.type,
          campaign: campaign || "",
          from: fromDate || "",
          to: toDate || "",
          input_text: searchText || "",
          status: status || "",  
          user_status: userType || "",
        };
      }

      console.log("post data   ------.   l ", status)
      
      const controller = new AbortController();
      const response = await axiosPrivate.get(
        process.env.REACT_APP_API_URL +
          "/contactcentre/download_campaign_report/",
        {
          signal: controller.signal,
          params: post_data,
        }
      );

      console.log("========response data", response.data);

      const response_data = response.data;

      try {
        if (type == "PDF") {
          downloadpdfData(response_data);
        } else {
          downloadxcelData(response_data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadpdfData = (dataset) => {
    try {
      // Use the updated downloadData
      // Create a new jsPDF instance
      const doc = new jsPDF();
      // Add content to the PDF
      doc.text("Campaign Report", 10, 10);

      // Extract table data and format it
        const tableData = dataset.flatMap((campaign_obj, index) => {
              return [
                [index < 9 ? `0${index + 1}` : `${index + 1}`, 'Campaign', campaign_obj.campaign_name],
                ['', 'Customer', `${campaign_obj.customer_name} (${campaign_obj.customer_number})`],
                ['', 'Agent', campaign_obj.agent_name],
                ['', 'Call Duration', campaign_obj.callDuration],
                ['', 'Connected Duration', campaign_obj.connectedDuration],
                ['', 'Agent Status', campaign_obj.agent_status],
                ['', 'Customer Status', campaign_obj.customer_status],
                ['', 'Call Time', campaign_obj.formated_date],
                ['', '', ''], // Empty row for spacing between entries
              ];
            });

      // // AutoTable plugin to generate the table
      doc.autoTable({
        head: [['S.No', 'Head', 'Data']], // Table headers
        body: tableData,                 // Data with serial number, key, and value
        startY: 20,                      // Adjust starting Y position
        styles: {
          cellPadding: 1,                // Add padding for better readability
          fontSize: 10,                  // Adjust font size
        },
        columnStyles: {
          0: { cellWidth: 20 },          // Width for the serial number column
          1: { cellWidth: 50 },          // Width for the key column
          2: { cellWidth: 110 },         // Width for the value column
        },
      });


      // Save or open the PDF
      doc.save("CampaignReport.pdf");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const downloadxcelData = (dataset) => {
    // Define the fields to exclude
    const excludedFields = [
      "call_uid",
      "customer_id",
      "id",
      "legaStartTime",
      "voice_file",
      "date",
      "callStatus",
    ];

    // Create a new array with the desired fields only
    const filteredData = dataset.map((item) => {
      const filteredItem = { ...item };
      excludedFields.forEach((field) => {
        delete filteredItem[field];
      });
      return filteredItem;
    });

    const excelData = [];

    filteredData.forEach((item) => {
      const data = {};
      console.log("<<<<<<<<<<<<<<", item, "+++++++++++=");
      data["Customer Name"] = item["customer_name"];
      data["Date"] = item["formated_date"];
      data["Connected Duration"] = item["connectedDuration"];
      data["Campaign"] = item["campaign_name"];
      data["Call Start Time"] = item["callStartTime"];
      data["Customer Number"] = item["customer_number"];
      data["Agent"] = item["agent_name"];
      data["Agent Status"] = item["agent_status"];
      data["Customer Status"] = item["customer_status"];
      data["Call Duration"] = item["callDuration"];

      console.log("CAMPAIN", campaign);

      if (campaign != "") {
        const callFeedbackLabels = item["call_feedback_labels"];
        Object.keys(callFeedbackLabels).forEach((key) => {
          data[key] = callFeedbackLabels[key];
        });

        const callFeedbackQuestions = item["call_feedback_questions"];
        Object.keys(callFeedbackQuestions).forEach((key) => {
          data[key] = callFeedbackQuestions[key];
        });

        data["Comment"] = item["call_feedback_comment"];
      }
      excelData.push(data);
    });

    console.log("<<<<<<<<<<<<", excelData);
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Create a worksheet
    const ws = XLSX.utils.json_to_sheet(excelData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Campaign Report");

    // Generate blob data for the workbook
    const blobData = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert the blob data to a Uint8Array
    const uint8Array = new Uint8Array(blobData);

    // Create a blob with the appropriate type
    const excelBlob = new Blob([uint8Array], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link and trigger the download
    const url = window.URL.createObjectURL(excelBlob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "campaign_report.xlsx"; // Specify the desired file name
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };


  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  // Filter options based on search term
  const filteredOptions = camplist.filter(option =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setShowMenu(true);
    if (e.target.value == '') {
      setCampaign("")
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setSearchTerm(option.name); // Show selected label in input
    setShowMenu(false); // Hide menu after selection
    setCampaign(option.id)

  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleBlur = () => {
    // Delay hiding menu to allow option selection
    setTimeout(() => setShowMenu(false), 150);
  };

  return (
    <div className="bg-white p-4">
      <Popup
        open={fback}
        modal
        nested
        position="center center"
        contentStyle={{ width: "0px", marginTop: "-10px" }}
      >
        {(close) => (
          <CallFeedback
            setfback={setfback}
            close={close}
            customer={FBcampData.customer_data || null}
            call_id={FBcampData.call_id || null}
            camp_data={FBcampData.camp_data || null}
            FBdata={fbdata}
            setFBdata={setfbdata}
            viewfb={true}
            setCampaignReportData={setCampaignReportData}
            campaignRepotData={campaignRepotData}
          />
        )}
      </Popup>

      <div className="flex flex-col">
        <div className="flex justify-between p-3 gap-4">
          <div className="flex justify-items-start gap-6">
            <div className="">
              <label htmlFor="table-search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for items"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
                <div className="relative">
                  {/* Searchable Input */}
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onClick={toggleMenu}
                    onBlur={handleBlur}
                    placeholder="Select Campaign"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  {/* Dropdown Menu */}
                  {showMenu && (
                    <ul
                      className="absolute left-0 right-0 mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10"
                      onMouseDown={(e) => e.preventDefault()} // Prevent input blur when interacting with dropdown
                    >
                      {filteredOptions.map((option) => (
                        <li
                          key={option.value}
                          onClick={() => handleOptionSelect(option)} // Handle option selection
                          className="cursor-pointer py-2 px-4 hover:bg-gray-200"
                        >
                          {option.name}
                        </li>
                      ))}
                      {filteredOptions.length === 0 && (
                        <li className="py-2 px-4 text-gray-500">No options found</li>
                      )}
                    </ul>
                  )}
                </div>

            {/*  Filter with Call Status  */}
            <div className="relative w-full flex space-x-2">
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <option value="all">Select Call Status</option>
                <option value={"ANSWERED"}>Answered</option>
                <option value={"NOANSWER"}>No Answered</option>
                <option value={"BUSY"}>Busy</option>
                <option value={"CANCEL"}>Cancel</option>
                <option value={"NOT_APPLICABLE"}>Not Applicable</option>
              </select>
            </div>
            <div className="relative w-full flex space-x-2">
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={(e) => {
                  setUserType(e.target.value);
                }}
              >
                <option value="all">User Type</option>
                <option value={"agent"}>Agent</option>
                <option value={"customer"}>Customer</option>
                <option value={"both"}>Both</option>
              </select>
            </div>
          </div>
        </div>

        {/*  PDF  & EXCEL DOWNLOAD */}

        <div className="flex justify-items-start gap-6 ml-3">
          <div
            className="flex justify-between space-x-3 bg-red-100 p-2 rounded-2xl cursor-pointer"
            onClick={() => downloadSheet("PDF")}
          >
            <Pdf className="w-5 h-5" />
            <h1 className="text-red-500">PDF</h1>
          </div>
          <div
            className="flex justify-between space-x-3 bg-green-100 p-2 rounded-2xl cursor-pointer"
            onClick={() => downloadSheet("EXCEL")}
          >
            <Excel className="w-5 h-5" />
            <h1 className="text-green-500">Excel</h1>
          </div>
          <p>From</p>
          <div className="relative flex space-x-2">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
              </svg>
            </div>
            <input
              name="start"
              type="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="From Date"
              onChange={(e) => {
                setFromDate(e.target.value);
              }}
            />
          </div>
          <p>To</p>
          {/* <span className="mx-4 text-gray-500">to</span> */}
          <div className="relative flex space-x-2">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
              </svg>
            </div>
            <input
              name="end"
              type="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="To Date"
              onChange={(e) => {
                setToDate(e.target.value);
              }}
            />
          </div>

          <div className="space-x-2">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={SearchCampaign}
            >
              Submit
            </button>
          </div>
        </div>

        <div className="py-2 inline-block min-w-full ">
          <div className="overflow-x-auto">
            <table className="w-full ml-[-4rem]">
              <thead>
                <tr className="text-[#9AA7B5] text-[17px] font-karla font-[500]">
                  <td className="pl-[6.0rem] pr-[2.1rem]  py-4 text-left">
                    Sl-No
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Customer
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Agent
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Campaign
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Call Duration(sec)
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Connected Duration(sec)
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Agent Status
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Customer Status
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Call Time
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Feedback
                  </td>

                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Play/Pause
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Download
                  </td>
                </tr>
              </thead>

              <tbody>
                {campaignRepotData.map((campaign_obj, index) => (
                  <tr
                    className="border-b text-[16px] font-karla text-gray-900  whitespace-nowrap"
                    key={0 + index}
                  >
                    <td className="pl-[6.0rem] pr-[2.1rem]  py-4">
                      {index +
                        1 +
                        (pagination?.current_page - 1) * pagination?.page_size}
                    </td>
                    <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                      {campaign_obj.customer_name}
                      <br />
                      {campaign_obj.customer_number}
                      <br />
                    </td>
                    <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                      {campaign_obj.agent_name}
                      <br />
                      {campaign_obj.agent_number}
                      <br />
                    </td>
                    <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                      {campaign_obj.campaign_name}
                    </td>
                    <td className="pl-[1.0rem] pr-[2.1rem]  py-4  text-left	">
                      {campaign_obj.callDuration}
                    </td>
                    <td className="pl-[1.0rem] pr-[2.1rem]  py-4  text-left	">
                      {campaign_obj.connectedDuration}
                    </td>
                    <td className="pl-[1.0rem] pr-[2.1rem] py-4 text-left	">
                      <div className="flex justify-start gap-2">
                        <div className="mt-[4px]">
                          <Outgoing
                            width={12}
                            height={12}
                            fill={`${
                              campaign_obj.agent_status == "ANSWERED"
                                ? "green"
                                : "red"
                            }`}
                          />
                        </div>
                        {campaign_obj.agent_status ? <p>{campaign_obj.agent_status}</p>:
                        <p>NOT APPLICABLE</p>}
                      </div>
                    </td>

                    <td className="pl-[1.0rem] pr-[2.1rem] py-4 text-left	">
                      <div className="flex justify-start gap-2">
                        <div className="mt-[4px]">
                          <Outgoing
                            width={12}
                            height={12}
                            fill={`${
                              campaign_obj.customer_status == "ANSWERED"
                                ? "green"
                                : "red"
                            }`}
                          />
                        </div>
                       {campaign_obj.customer_status ? <p>{campaign_obj.customer_status}</p>:
                        <p>NOT APPLICABLE</p>}
                      </div>
                    </td>

                    <td className="pl-[1.1rem] pr-[0.1rem] py-4 text-left	">
                      {campaign_obj.formated_date}
                    </td>
                    {campaign_obj.call_feedback ? (
                      <td
                        className="pl-[1.0rem] pr-[2.1rem]  py-4 text-center cursor-pointer"
                        onClick={() => {
                          SetFeedbackData(
                            campaign_obj.agent_id,
                            campaign_obj.call_uid
                          );
                        }}
                      >
                        <svg
                          width="21"
                          height="15"
                          viewBox="0 0 21 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.9235 7.125C17.9035 2.72813 14.1035 0 10.0035 0C5.90348 0 2.10348 2.72813 0.0834848 7.125C0.0284215 7.24328 0 7.37095 0 7.5C0 7.62905 0.0284215 7.75672 0.0834848 7.875C2.10348 12.2719 5.90348 15 10.0035 15C14.1035 15 17.9035 12.2719 19.9235 7.875C19.9785 7.75672 20.007 7.62905 20.007 7.5C20.007 7.37095 19.9785 7.24328 19.9235 7.125ZM10.0035 13.125C6.83348 13.125 3.83348 10.9781 2.10348 7.5C3.83348 4.02187 6.83348 1.875 10.0035 1.875C13.1735 1.875 16.1735 4.02187 17.9035 7.5C16.1735 10.9781 13.1735 13.125 10.0035 13.125ZM10.0035 3.75C9.21236 3.75 8.439 3.96993 7.7812 4.38199C7.12341 4.79404 6.61072 5.37971 6.30797 6.06494C6.00522 6.75016 5.926 7.50416 6.08034 8.23159C6.23468 8.95902 6.61565 9.6272 7.17506 10.1517C7.73447 10.6761 8.4472 11.0333 9.22312 11.1779C9.99905 11.3226 10.8033 11.2484 11.5342 10.9645C12.2651 10.6807 12.8898 10.2001 13.3294 9.58339C13.7689 8.9667 14.0035 8.24168 14.0035 7.5C14.0035 6.50544 13.5821 5.55161 12.8319 4.84835C12.0818 4.14509 11.0644 3.75 10.0035 3.75ZM10.0035 9.375C9.60792 9.375 9.22124 9.26503 8.89234 9.05901C8.56345 8.85298 8.3071 8.56014 8.15573 8.21753C8.00435 7.87492 7.96474 7.49792 8.04191 7.13421C8.11908 6.77049 8.30957 6.4364 8.58927 6.17418C8.86898 5.91195 9.22534 5.73337 9.6133 5.66103C10.0013 5.58868 10.4034 5.62581 10.7689 5.76773C11.1343 5.90964 11.4467 6.14996 11.6664 6.45831C11.8862 6.76665 12.0035 7.12916 12.0035 7.5C12.0035 7.99728 11.7928 8.47419 11.4177 8.82582C11.0426 9.17746 10.5339 9.375 10.0035 9.375Z"
                            fill="#7C8998"
                          />
                        </svg>
                      </td>
                    ) : (
                      <td
                        className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left cursor-pointer"
                        onClick={() =>
                          AddFeedback(
                            campaign_obj.agent_id,
                            campaign_obj.call_uid,
                            campaign_obj.customer_id
                          )
                        }
                      >
                        +
                      </td>
                    )}

                    <td className="pl-[1.1rem] cursor-pointer  pr-[0.1rem] py-4 text-left">
                      {campaign_obj.voice_file &&
                      campaign_obj.callStatus === "ANSWERED" ? (
                        <audio
                          id={campaign_obj.voice_file}
                          preload="true"
                          controls
                        >
                          <source
                            key={campaign_obj.voice_file}
                            src={`${BASE_URL}/uploads/downloadedWaveFile/${campaign_obj.voice_file.replace(
                              ".gsm",
                              ".wav"
                            )}`}
                            type="audio/wav"
                          />
                        </audio>
                      ) : (
                        <p>No audio</p>
                      )}
                    </td>
                    <td className="pl-[2.1rem] cursor-pointer pr-[0.1rem] py-4 text-center	">
                      <svg
                        width="25px"
                        height="25px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          DownloadRecord(
                            campaign_obj.voice_file,
                            campaign_obj.legaStartTime,
                            index + 1
                          );
                        }}
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 3C12.5523 3 13 3.44772 13 4V12.5858L15.2929 10.2929C15.6834 9.90237 16.3166 9.90237 16.7071 10.2929C17.0976 10.6834 17.0976 11.3166 16.7071 11.7071L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16C11.7348 16 11.4804 15.8946 11.2929 15.7071L7.2929 11.7071C6.90238 11.3166 6.90238 10.6834 7.2929 10.2929C7.68342 9.90237 8.31659 9.90237 8.70711 10.2929L11 12.5858V4C11 3.44772 11.4477 3 12 3ZM4.00001 14C4.55229 14 5.00001 14.4477 5.00001 15C5.00001 15.9772 5.00485 16.3198 5.05765 16.5853C5.29437 17.7753 6.22466 18.7056 7.41474 18.9424C7.68018 18.9952 8.02276 19 9.00001 19H15C15.9772 19 16.3198 18.9952 16.5853 18.9424C17.7753 18.7056 18.7056 17.7753 18.9424 16.5853C18.9952 16.3198 19 15.9772 19 15C19 14.4477 19.4477 14 20 14C20.5523 14 21 14.4477 21 15C21 15.0392 21 15.0777 21 15.1157C21.0002 15.9334 21.0004 16.4906 20.9039 16.9755C20.5094 18.9589 18.9589 20.5094 16.9755 20.9039C16.4907 21.0004 15.9334 21.0002 15.1158 21C15.0778 21 15.0392 21 15 21H9.00001C8.96084 21 8.92225 21 8.88423 21C8.06664 21.0002 7.50935 21.0004 7.02456 20.9039C5.0411 20.5094 3.49061 18.9589 3.09608 16.9755C2.99965 16.4906 2.99978 15.9334 2.99999 15.1158C3 15.0777 3.00001 15.0392 3.00001 15C3.00001 14.4477 3.44772 14 4.00001 14Z"
                          fill="#0F1729"
                        />
                      </svg>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="p-8">
          <Pagination
            pagination_data={pagination}
            PaginationControl={PaginationControl}
          />
        </div>
      </div>
    </div>
  );
}
export default CampaignReport;
