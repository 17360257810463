import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import useFetch from "../../hooks/useFetch";
import { CAMP_CONTROLL } from "../../utils/ApiUrls";
import { ReactComponent as Start } from "../../assets/images/svg/Start.svg";
import { ReactComponent as Stop } from "../../assets/images/svg/stop.svg";
import { ReactComponent as Edit } from "../../assets/images/svg/Edit.svg";
import { ReactComponent as Delete } from "../../assets/images/svg/Delete.svg";
import { Link } from "react-router-dom";
import { TopDisappearingMessage } from "../../utils/Common";
import Pagination from "../../components/Table/pagination";
import Tooltip from "../../components/Elements/Tooltip";

import axios from "axios";
import Swal from "sweetalert2";

const CampaignList = (props) => {
  const { auth } = useAuth();
  const tooltipText = ["Item 1", "Item 2", "Item 3"];

  const Initial_pagination = {
    next: "",
    previous: "",
    count: "",
    total_pages: "",
    current_page: 1,
    page_size: 5,
  };

  const [campaigns, setcampaigns] = useState([]);
  const [pagination, setPagination] = useState(Initial_pagination);
  const [searchtext, setsearchtext] = useState("");
  const PaginationControl = (type, number) => {
    if (type == "page") {
      if (number <= pagination.total_pages) {
        fetchCampaignData(number);
      } else {
        fetchCampaignData(1);
      }
    } else if (type == "last") {
      fetchCampaignData(pagination.total_pages);
    } else if (type == "prev") {
      if (pagination.current_page > 1) {
        fetchCampaignData(pagination.current_page - 1);
      }
    } else if (type == "next") {
      if (pagination.current_page < pagination.total_pages) {
        fetchCampaignData(pagination.current_page + 1);
      }
    } else {
      fetchCampaignData(1);
    }
  };

  const SearchSubmit = () => {
    fetchCampaignData(1);
  };

  const fetchCampaignData = async (pagenumber) => {
    let camp_post_data;

    if (props.user_type === "agent") {
      camp_post_data = {
        user_id: auth?.user_details?.useragent,
        user_type: props.user_type,
        page: pagenumber,
        search: searchtext,
      };
    } else {
      camp_post_data = {
        user_id: auth?.user_details?.user,
        user_type: props.user_type,
        page: pagenumber,
        search: searchtext,
      };
    }

    let config = {
      headers: { "Content-Type": "application/json" },
    };

    await axios
      .get(
        process.env.REACT_APP_API_URL + "/contactcentre/campaign-list/",
        {
          params: camp_post_data,
        },
        config
      )
      .then((res) => {
        if (props.camp_search) {
          setcampaigns(res.data.results);

          setPagination((prevState) => {
            return {
              ...prevState,
              total_pages: res.data?.total_pages || 0,
              previous: res.data?.previous || null,
              next: res.data?.next || null,
              current_page: res.data?.current_page || 0,
              count: res.data?.count || 0,
              page_size: res.data?.page_size || 5,
            };
          });
        } else {
          setcampaigns(res?.data?.results?.slice(0, 5) || []);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchCampaignData(1);
    console.log("PROPS                             :", props);
  }, []);

  const fetchData = useFetch();

  const CampaignControl = async (camp_id, status, index) => {
    let post_data = {
      campaign_id: camp_id,
      status: status,
      userId: auth?.user_details?.user,
    };

    console.log("=======camp_id==,status===", post_data);
    const res_data = await fetchData("post", post_data, CAMP_CONTROLL);
    if (res_data?.data === "success") {
      TopDisappearingMessage(
        "top-start",
        2000,
        "warning",
        "CAMPAIGN STARTED FOR ALL ACTIVE AGENTS",
        false
      );
    }

    if (res_data) {
      if (status === "start") {
        TopDisappearingMessage(
          "top-start",
          2000,
          "success",
          "Campaign started for all active agents",
          false
        );
      }
      if (status === "stop") {
        TopDisappearingMessage(
          "top-start",
          2000,
          "warning",
          "Campaign stoped for all active agents",
          false
        );
      }
      var data = [...campaigns];
      data[index].campaign_status = status;
    }
  };

  const CampaignDelete = async (camp_id, status, index) => {
    console.log("<<<<<STATUS>>>>>", status);
    if (status === "start") {
      Swal.fire({
        title: "Do not delete !",
        text: "This campaign is currently running...",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      });
    } else {
      Swal.fire({
        title: "Are you sure to Delete?",
        text: "All data associated with this campaign will be deleted",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setcampaigns((current) =>
            current.filter((fruit) => fruit.id !== camp_id)
          );

          let post_data = {
            user_id: auth?.user_details?.user,
            camp_id: camp_id,
          };

          let config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth?.accessToken}`,
            },
          };

          await axios
            .post(
              process.env.REACT_APP_API_URL + "/contactcentre/delete_campaign/",
              post_data,
              config
            )
            .then((res) => {
              console.log("=camp list loaded=", res.data);
            })
            .catch((err) => console.log(err));
        }
      });
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-between ">
          {props.camp_search && (
            <div className="flex justify-items-start">
              <div className="">
                <label htmlFor="table-search" className="sr-only">
                  Search
                </label>
                <div className="flex justify-items-start">
                  <input
                    type="text"
                    id="table-search"
                    onChange={(e) => {
                      setsearchtext(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 h-10 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search for items"
                  />

                  <div
                    onClick={SearchSubmit}
                    className="rounded cursor-pointer group  flex items-center justify-center h-10 w-10 ml-[-53px]"
                  >
                    <svg
                      className="w-5 h-5 text-gray-500 dark:text-gray-400 group-hover:scale-[1.3] group-hover:text-gray-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          )}

          {props.type === "campaignlist_admin" && (
            <Link to="/admin/campaign/create/0">
              <button
                type="button"
                className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
              >
                + ADD CAMPAIGN
              </button>
            </Link>
          )}
        </div>

        <div className="py-2 inline-block min-w-full ">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-[#9AA7B5] text-[17px] font-karla font-[500]">
                  {props.theadData.map((item) => {
                    return (
                      <th
                        key={item}
                        title={item}
                        className="w-full font-[500] pl-[2.1rem] pr-[1.5rem] text-start"
                      >
                        {item}
                      </th>
                    );
                  })}

                  {props.type === "campaignlist_admin" && (
                    <>
                      <th
                        key="start-stop"
                        title="Start/Stop"
                        className="w-full font-[500] pl-[2.1rem] pr-[1.5rem] text-start"
                      >
                        {" "}
                        Start/Stop
                      </th>

                      <th
                        key="action"
                        title="action"
                        className="w-full font-[500] pl-[2.1rem] pr-[1.5rem] text-start"
                      >
                        {" "}
                        Action
                      </th>
                    </>
                  )}
                </tr>
              </thead>

              <tbody>
                {campaigns &&
                  campaigns.map &&
                  campaigns.map((campaign, index) => (
                    <tr
                      className="border-b text-[16px] font-karla text-gray-900  whitespace-nowrap"
                      key={0 + index}
                    >
                      <td className="py-4 pl-[2.1rem] pr-[1.5rem]">
                        {index +
                          1 +
                          (pagination?.current_page - 1) *
                            pagination?.page_size}
                      </td>
                      <td className="pl-[2.1rem] pr-[1.5rem]  py-4 ">
                        {campaign.name}
                      </td>

                      {campaign.campaign_status === "start" && (
                        <td className="pl-[2.1rem] pr-[1.5rem]  py-4 text-center">
                          <span className="inline-flex items-center gap-1 rounded-full bg-yellow-50 px-2 py-1 text-xs font-semibold  text-[#fbbf24]">
                            <span className="h-1.5 w-1.5 rounded-full bg-[#fbbf24]"></span>
                            Ongoing
                          </span>
                        </td>
                      )}

                      {campaign.campaign_status === "stop" && (
                        <td className="pl-[2.1rem] pr-[1.5rem]  py-4 text-center">
                          <span className="inline-flex items-center gap-1 rounded-full bg-red-50 px-2 py-1 text-xs font-semibold text-red-600">
                            <span className="h-1.5 w-1.5 rounded-full bg-red-600"></span>
                            Not Started
                          </span>
                        </td>
                      )}

                      {campaign.campaign_status === "completed" && (
                        <td className="pl-[2.1rem] pr-[1.5rem]  py-4 text-center text-[#26ae0b]">
                          <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
                            <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                            Completed
                          </span>
                        </td>
                      )}

                      <td className="pl-[2.1rem] pr-[1.5rem]  py-4 ">
                        {campaign.campaign_mode || "-"}{" "}
                      </td>
                      <td className="pl-[2.1rem] pr-[1.5rem]  py-4  text-center	">
                        {campaign.total_customers}
                      </td>

                      <td className="pl-[2.1rem] pr-[1.5rem]  py-4 text-center	">
                        {campaign.called_customers}
                        {/* <div className="flex items-center justify-between">
                        <span className="text-slate-400 text-sm text-slate-500">Progress 3</span>
                        <span className="px-2 py-1 bg-teal-50 rounded-lg text-xs text-teal-400 font-medium min-w-[46px] text-center">50%</span>
                    </div> */}
                      </td>
                      <td className="pl-[2.1rem] pr-[1.5rem]  py-4 text-center	">
                        {campaign.tobe_called}
                      </td>
                      <td className="pl-[2.1rem] pr-[1.5rem]  py-4 text-center	">
                        {campaign.no_of_agents || 0}
                      </td>
                      <td className="pl-[2.1rem] pr-[1.5rem]  py-4 text-center	">
                        {campaign.formated_scheduled_time}
                      </td>

                      {props.type === "campaignlist_admin" && (
                        <>
                          <td className="pl-[2.1rem] pr-[1.5rem]  py-4 text-center	">
                            {campaign.campaign_status === "stop" && (
                              <div
                                onClick={() => {
                                  CampaignControl(campaign.id, "start", index);
                                }}
                                className="flex justify-center rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4"
                              >
                                <Start
                                  className={`width="26" height="26" fill="blue"`}
                                  viewBox="0 0 26 26"
                                  fill="green"
                                  cx="8.5"
                                  cy="8.5"
                                  r="6"
                                  fillRule="evenodd"
                                  fillOpacity=".8"
                                />
                              </div>
                            )}

                            {campaign.campaign_status === "start" && (
                              <div
                                onClick={() => {
                                  CampaignControl(campaign.id, "stop", index);
                                }}
                                className="flex justify-center rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4"
                              >
                                <Stop
                                  className={`width="26" height="26" fill="blue"`}
                                  viewBox="0 0 26 26"
                                  fill="red"
                                  cx="8.5"
                                  cy="8.5"
                                  r="6"
                                  fillRule="evenodd"
                                  fillOpacity=".8"
                                />
                              </div>
                            )}
                          </td>
                          <td className="p-3 flex">
                            <div className="flex justify-between">
                              <Link
                                to={`/admin/campaign/create/${campaign.id}`}
                              >
                                <div className="flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4">
                                  <Edit
                                    className={`width="26" height="26" fill="blue"`}
                                    viewBox="0 0 26 26"
                                    fill="green"
                                    cx="8.5"
                                    cy="8.5"
                                    r="6"
                                    fillRule="evenodd"
                                    fillOpacity=".8"
                                  />
                                </div>
                              </Link>
                              <div
                                onClick={() => {
                                  CampaignDelete(
                                    campaign.id,
                                    campaign.campaign_status,
                                    "stop",
                                    index
                                  );
                                }}
                                className="flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4"
                              >
                                <Delete
                                  className={`width="26" height="26" fill="blue"`}
                                  viewBox="0 0 26 26"
                                  fill="red"
                                  cx="8.5"
                                  cy="8.5"
                                  r="6"
                                  fillRule="evenodd"
                                  fillOpacity=".8"
                                />
                              </div>
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {props.camp_search && (
          <div className="p-8">
            <Pagination
              pagination_data={pagination}
              PaginationControl={PaginationControl}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default React.memo(CampaignList);
